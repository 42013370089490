<template>
	<div class="row">
		<div class="form-group col-md-2">
			<label for="filter_text">Estado</label>
			<select class="form-control form-control-sm" v-model="state" @change="mtd_filter">
				<option value="0">Sin firmar</option>
				<option value="1">Firmados</option>
				<option value="">Todos</option>
			</select>
		</div>
		<div class="form-group col-md-5">
			<label for="filter_text">Buscar</label>
			<div class="input-group">
				<input placeholder="Buscar por DNI o Nombres y apellidos" v-model="text" @keypress.enter="mtd_filter" class="form-control form-control-sm" id="filter_text" type="text" />
				<button style="border-bottom-left-radius: 0; border-top-left-radius: 0" @click="mtd_filter" class="btn btn-primary btn-xs">Filtrar</button>
			</div>
		</div>
	</div>
</template>
<script>
/** Vuex */
import { mapActions, mapGetters } from 'vuex';
export default {
	props: ['prop-execute', 'page'],
	data() {
		return {
			requirements: {
				enterprises: [],
			},
			enterprise_id: null,
			text: '',
			state: 0,
		};
	},
	created() {
		this.mtd_executeFilter('new');
	},
	computed: {
		...mapGetters(['url_api']),
	},
	watch: {
		propExecute: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
		page: function (newValue) {
			if (newValue) this.mtd_executeFilter();
		},
	},
	methods: {
		...mapActions(['get', 'post', 'get_sin_bus', 'execute_commit']),
		mtd_filter: function () {
			this.mtd_executeFilter('new');
			this.$emit('emit-init-pagination');
		},
		mtd_executeFilter: function (type = null) {
			let pagination = null;
			type == 'new' ? (pagination = 1) : (pagination = this.page);

			const params = {
				url: `${this.url_api}/finalists?&page=${pagination}&text=${this.text}&state=${this.state}`,
				mutation: 'contract_responsable/POSTULANTS_INDEX__SET_ALL',
			};
			this.get(params);
		},
	},
};
</script>
