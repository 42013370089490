<template>
	<div class="modal-body form form-horizontal">
		<!-- CREAR -->
		<div v-if="propInfo.data.method == 'definition'" class="row">
			<!-- DATOS DEL POSTULANTE -->
			<div class="col-md-12">
				<h5>Datos del postulante</h5>
			</div>
			<!-- TIPO DE DOC. -->
			<div class="col-md-6">
				<label class="col-form-label label-modal" for="business_id">Tipo de Doc.</label>
				<p class="mb-0">{{ propInfo.data.row.postulant_type_document }}</p>
			</div>
			<!-- NUMERO DE DOCUMENTO -->
			<div class="col-md-6">
				<label class="col-form-label label-modal">Número de Doc.</label>
				<p class="mb-0">{{ propInfo.data.row.postulant_document }}</p>
			</div>
			<!-- NOMBRES COMPLETOS -->
			<div class="col-md-12">
				<label class="col-form-label label-modal">Nombre</label>
				<p class="mb-0">{{ propInfo.data.row.postulant_names }}</p>
			</div>
			<!-- CELULAR -->
			<div class="col-md-6">
				<label class="col-form-label label-modal">Celular</label>
				<p class="mb-0">{{ propInfo.data.row.postulant_cellphone }}</p>
			</div>
			<!-- EMAIL -->
			<div class="col-md-6">
				<label class="col-form-label label-modal">Correo</label>
				<p class="mb-0">{{ propInfo.data.row.postulant_email }}</p>
			</div>
			<!----------- RESULTADOS ------->
			<div class="col-md-12 mt-3">
				<h5>Ingreso de resultados</h5>
			</div>
			<!-- TIPO -->
			<div class="col-md-6 form-group">
				<label class="col-form-label label-modal">Tipo de contrato</label>
				<select v-model="type" class="form-control form-control-sm" name="type" v-validate="'required'" data-vv-as="'Estado'">
					<option :value="null">Seleccionar</option>
					<option value="1">CONTRATO DE LOCACIÓN DE SERVICIOS PROFESIONALES</option>
					<option value="2">CONTRATO DE TRABAJO SUJETO MODALIDAD POR SERVICIO ESPECÍFICO</option>
					<option value="3">CONTRATO DE TRABAJO POR INCREMENTO DE ACTIVIDAD</option>
				</select>
				<span v-show="errors.has('type')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('type') }} </span>
			</div>
			<!-- DURACIÓN -->
			<div class="col-md-6 form-group" v-if="type == 1 || type == 2">
				<label class="col-form-label label-modal">Duración (meses)</label>
				<input v-model="duration" type="text" name="duration" class="form-control form-control-sm" v-validate="'required'" data-vv-as="'Duración'" />
				<span v-show="errors.has('duration')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('duration') }} </span>
			</div>
			<!-- INICIO DE LABORES -->
			<div class="col-md-6 form-group">
				<label class="col-form-label label-modal d-block">Inicio de labores</label>
				<flat-pickr
					v-model="date"
					:config="{}"
					class="form-control form-control-sm flatpickr-input"
					placeholder="Seleccionar"
					name="date"
					v-validate="'required'"
					data-vv-as="'Fecha de cese'"></flat-pickr>
				<span v-show="errors.has('date')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('date') }} </span>
			</div>
			<!-- FIN DE LABORES -->
			<div class="col-md-6 form-group" v-if="type == 2 || type == 3">
				<label class="col-form-label label-modal d-block">Fin de labores</label>
				<flat-pickr
					v-model="date_finish"
					:config="{
						minDate: new Date(),
					}"
					class="form-control form-control-sm flatpickr-input"
					placeholder="Seleccionar"
					name="date_finish"
					v-validate="'required'"
					data-vv-as="'Fecha de cese'"></flat-pickr>
				<span v-show="errors.has('date_finish')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('date_finish') }} </span>
			</div>
			<!-- PROFESIÓN -->
			<div class="col-md-6 form-group" v-if="type == 1">
				<label class="col-form-label label-modal">Profesión</label>
				<input v-model="profession" type="text" name="profession" class="form-control form-control-sm" v-validate="'required'" data-vv-as="'Profesión'" />
				<span v-show="errors.has('profession')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('profession') }} </span>
			</div>
			<!-- DIRECCIÓN -->
			<div class="col-md-12 form-group">
				<label class="col-form-label label-modal">Dirección</label>
				<input v-model="address" type="text" name="address" class="form-control form-control-sm text-uppercase" v-validate="'required'" data-vv-as="'Dirección'" />
				<span v-show="errors.has('address')" class="form-group__error"><i class="fas fa-exclamation-circle"></i> {{ errors.first('address') }} </span>
			</div>
		</div>

		<div class="row mt-5">
			<div class="col-12 d-flex justify-content-end">
				<button @click="mtd_send_preview" class="btn btn-info mr-3">Vista previa</button>
				<button @click="mtd_send" class="btn btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
				<button @click="mtd_close" class="btn btn-secondary">Cancelar</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
		modal_id: {
			required: true,
		},
	},
	data() {
		return {
			type: null,
			date: null,
			date_finish: null,
			duration: '',
			profession: '',
			address: '',
			errorsBackend: {},
			methods: {
				init: {
					definition: 'this.mtd_onDefinition()',
				},
				action: {
					definition: 'this.mtd_store()',
				},
			},
		};
	},

	computed: {
		...mapGetters(['url_api', 'url_web']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update', 'postWithFile']),
		mtd_onDefinition() {
			this.mtd_definition();
		},
		mtd_definition() {
			this.$emit('onload', { status: 'success' });
		},
		mtd_send_preview: function () {
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.mtd_preview();
				}
			});
		},
		mtd_preview: function () {
			this.post({
				url: `${this.url_api}/finalists/${this.propInfo.data.row.id}/update`,
				responseType: 'blob',
				params: {
					type: this.type,
					date: this.date,
					date_finish: this.date_finish,
					duration: this.duration,
					profession: this.profession,
					address: this.address,
					state: 0,
				},
			}).then(() => {
				window.open(`${this.url_web}/download/contract/${this.propInfo.data.row.id}`);
			});
		},
		mtd_store: function () {
			this.$swal
				.fire({
					icon: 'warning',
					title: '¿Está seguro que desea enviar?',
					showCancelButton: true,
					confirmButtonText: 'Si, enviar',
				})
				.then(result => {
					if (result.isConfirmed) {
						this.post({
							url: `${this.url_api}/finalists/${this.propInfo.data.row.id}/update`,
							params: {
								type: this.type,
								date: this.date,
								date_finish: this.date_finish,
								duration: this.duration,
								profession: this.profession,
								address: this.address,
								state: 1,
							},
						}).then(res => {
							if (res.state == 'success') {
								this.$emit('success', {
									component: 'programming-results-modal',
									data: {},
									action: 'store',
									modal_id: 'programming-results-modal',
								});
								this.$swal.fire({
									icon: 'success',
									title: 'Enviado',
									text: `Contrato guardado correctamente.`,
									showCancelButton: false,
								});
							}
							if (res.state == 'exception') {
								window.simplealert('error', res.message, '<i class="fas fa-bomb"></i> Error interno');
							}
						});
					}
				});
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
		handleFileUpload() {
			let file_new = this.$refs.file.files;
			this.file = file_new[0];
		},
	},
};
</script>
