<template>
	<div class="main-body p-0">
		<div class="inner-wrapper">
			<div class="inner-main">
				<page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel"></page-header>
				<div class="inner-main-body">
					<page-body
						:propHeader="dataTable.header"
						:propBody="dataTable.body"
						:propModel="dataModel"
						@action-next-pagination="mtd_nextPagination"
						@download="mtd_download"
						@programming-results-modal="mtd_showProgrammingResultsModal">
						<template slot="content-filter">
							<component :is="filter_component" @emit-init-pagination="mtd_initPagination" :prop-execute="executeFilter" :page="dataTable.header.page"></component>
						</template>
					</page-body>
				</div>
			</div>
		</div>

		<modal v-for="modal in modals" :key="modal.id" class="bg-black-trans" :id="modal.id" :propConfig="modal.config" @action-close="mtd_closeModal(modal.id)">
			<template v-if="modal.id == 'programming-results-modal'">
				<component
					:is="modal.slot.component"
					slot="content-modal"
					:propInfo="modal.slot"
					:modal_id="modal.id"
					@onload="mtd_componentOnload(modal.id)"
					@success="mtd_componentSuccess"
					@error="mtd_componentError"
					@close="mtd_closeModal(modal.id)"></component>
			</template>
		</modal>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import MainFilter from './components/MainFilter';
import ProgrammingResultsModal from './components/ProgrammingResults';

export default {
	components: {
		MainFilter,
		ProgrammingResultsModal,
	},
	data() {
		return {
			filter_component: 'main-filter',
			executeFilter: 0,
			dataModel: {
				getter: 'contract_responsable/postulants_index',
				prefixPermission: '',
				alias: 'Postulantes',
				icon: 'fas fa-users',
			},
			page: {
				header: {
					menu: [],
				},
				buttons: {
					create: false,
					import: false,
					export: false,
				},
			},
			dataTable: {
				header: {
					title: 'Postulantes',
					filter: false,
					page: 1,
					wordsearch: '',
				},
				body: {
					cols: [
						{
							name: 'requirement_name',
							alias: 'Requerimiento',
							width: '',
						},
						{
							name: 'job_position_name',
							alias: 'Puesto',
							width: '',
						},
						{
							name: 'postulant_names',
							alias: 'Nombre',
							width: '',
						},
						{
							name: 'postulant_cellphone',
							alias: 'Celular',
							width: '',
						},
						{
							name: 'postulant_email',
							alias: 'Correo',
							width: '',
						},
						{
							name: 'state',
							alias: 'Estado',
							width: '',
							play: {
								0: "<span class='badge badge-danger'>Sin firmar</span>",
								1: "<span class='badge badge-success'>Firmado</span>",
							},
						},
					],
					buttons: {
						edit: false,
						remove: false,
						width: '',
					},
					myButtons: [
						{
							text: "<i class='fas fa-marker'></i>",
							class: 'btn-xs btn-warning',
							tooltip: 'Firmar',
							component: 'programming-results-modal',
							can: '',
							play: {
								type: 'state',
								name: 'state',
								1: false,
								0: true,
							},
						},
						{
							text: "<i class='fas fa-marker'></i>",
							class: 'btn-xs btn-info',
							tooltip: 'Descargar contrato',
							component: 'download',
							play: {
								type: 'state',
								name: 'state',
								0: false,
								1: true,
							},
						},
					],
					sonWindow: {
						component: null,
						row: null,
					},
				},
			},
			modals: [
				{
					id: 'programming-results-modal',
					slot: {
						component: null,
						data: {
							row: null,
							buttonSubmit: null,
							method: null,
						},
					},
					config: {
						title: null,
						id: 'programming-results-modal',
						idHash: '#programming-results-modal',
						size: null,
						scroll: true,
						position: '',
					},
				},
			],
		};
	},
	computed: {
		...mapGetters(['url_api', 'url_web']),
	},
	methods: {
		...mapActions(['get', 'post', 'destroy', 'execute_commit', 'update']),
		///////////////////// METODO POR DEFECTO /////////////////////
		// eslint-disable-next-line no-unused-vars
		mtd_componentSuccess: function ({ component, res, action, modal_id }) {
			switch (component) {
				case 'programming-results-modal':
					this.dataTable.header.page = 0;
					this.executeFilter++;
					this.mtd_closeModal(modal_id);
					break;
				default:
					window.message('Warning', `No existe el modal ${component}`);
					break;
			}
		},

		mtd_showProgrammingResultsModal(row) {
			const main_form = this.modals.find(el => el.id == 'programming-results-modal');

			main_form.slot.component = 'programming-results-modal';
			main_form.slot.data = {
				row: row,
				method: 'definition',
				buttonSubmit: 'Guardar',
			};
			main_form.config.scroll = true;
			main_form.config.size = 'modal-md';
			main_form.config.position = 'modal-left';
			main_form.config.title = "<span class='fas fa-calendar-alt'></span> Resultados del postulante";
		},

		mtd_componentOnload: function (modal_id) {
			window.$(`#${modal_id}`).modal('show');
		},
		mtd_componentError: function () {},
		mtd_closeModal: function (modal_id) {
			window.$(`#${modal_id}`).modal('hide');
			this.modals.find(el => el.id == modal_id).slot = {
				component: null,
				data: {
					row: null,
					buttonSubmit: null,
					method: null,
				},
			};
		},
		mtd_sonWindowClose: function () {
			this.dataTable.body.sonWindow = {
				component: null,
				row: null,
			};
		},
		mtd_nextPagination: function (page) {
			this.dataTable.header.page = page;
		},
		mtd_initPagination: function () {
			this.dataTable.header.page = 0;
		},
		mtd_download: function (row) {
			window.open(`${this.url_web}/download/contract/${row.id}`);
		},
	},
	beforeDestroy() {
		this.execute_commit({ mutation: 'JOB_POSITIONS_INDEX__CLEAR_ALL' });
	},
};
</script>
